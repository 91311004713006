import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface ModalsOverlays {
  inTransitInscriptionRequet: boolean;
  panelInfoSubjectOffered: boolean,
  panelInfoSubjectInscribed: boolean,
  panelFilters: boolean,
}

const initialState: ModalsOverlays = {
  inTransitInscriptionRequet: false,
  panelInfoSubjectOffered: false,
  panelInfoSubjectInscribed: false,
  panelFilters: false,
};

export const globalFlagsSlice = createSlice({
  name: "globalFlags",
  initialState,
  reducers: {
    setInTransitInscriptionRequest: (state, action) => {
      state.inTransitInscriptionRequet = action.payload;
    },
    setPanelSubjectOffered: (state, action) => {
      state.panelInfoSubjectOffered = action.payload;
    },
    setPanelSubjectInscribed: (state, action) => {
      state.panelInfoSubjectInscribed = action.payload;
    },
    setPanelFilters: (state, action) => {
      state.panelFilters = action.payload;
    },
  },
});

export const selectInTransitInRquest = (state: RootState) => state.globalFlags.inTransitInscriptionRequet;
export const selectPanelSubjectOffered = (state: RootState) => state.globalFlags.panelInfoSubjectOffered;
export const selectPanelSubjectInscribed = (state: RootState) => state.globalFlags.panelInfoSubjectInscribed;
export const selectPanelFilters = (state: RootState) => state.globalFlags.panelFilters;

export const { setInTransitInscriptionRequest } = globalFlagsSlice.actions;
export const { setPanelSubjectOffered } = globalFlagsSlice.actions;
export const { setPanelSubjectInscribed } = globalFlagsSlice.actions;
export const { setPanelFilters } = globalFlagsSlice.actions;

export default globalFlagsSlice.reducer;
