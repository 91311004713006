import axios from "axios";

export const getEditorModeState = async (idAlumno:number) => {
  try {
    const { data, status } = await axios.post("/api/editorMode", { idAlumno: idAlumno });
    return data;
  } catch (error) {
    return { error };
  }
};
export const setEditorModeState = async (idAlumno:number, state:number) => {
  try {
    const { data, status } = await axios.post("/api/setEditorMode", { idAlumno: idAlumno, state: state });
    return data;
  } catch (error) {
    return { error };
  }
};
export const confirmChanges = async () => {
  try {
    const { data, status } = await axios.post("/api/confirmChanges", { });
    return data;
  } catch (error) {
    return { error };
  }
};
export const getRemainingTimeEdition = async (idAlumno:number) => {
  try {
    const { data, status } = await axios.post("/api/checkTimeLeftModoEdicion", { id:idAlumno });
    return data;
  } catch (error) {
    return { error };
  }
};