import "./UnknownUserScreen.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";


export default function UnknownUserScreen() {
  return (
    <div className="unknown-screen-container">
      <div className="unknown-alert-box">
        <SlIcon className="unknown-alert-icon" name="person-fill-x"></SlIcon>
        <div className="">
          <h2>Usuario inexistente</h2>
          <p>El mail o usuario no se encuentran habilitados en la plataforma.</p>
        </div>
      </div>
    </div>
  );
}
