import "./DesktopHeader.css";
import Logo from "../../assets/Logo";
import { UserMenu } from "../UserMenu/UserMenu";
import { useSelector } from "react-redux";
import { selectStudent } from "../../reduxSlices/studentSlice";

const DesktopHeader = () => {
  const { carreraActual } = useSelector(selectStudent);

  return (
    <div className="desktop-header">
      <div className="desktop-header-info">
        <div className="desktop-header-info-logo">
          <Logo />
          {carreraActual && (
            <p>
              <span>Carrera: </span>
              {carreraActual}
            </p>
          )}
        </div>
        <div className="desktop-header-info-data">
          <UserMenu />
        </div>
      </div>
    </div>
  );
};

export default DesktopHeader;
