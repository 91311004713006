import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ParametersState } from "../reduxSlices/parametersSlice";
import { getModoCarrito } from "../Api/parameters";
import { getPrioridad } from "../Api/prioridad";
import { StudentState } from "../reduxSlices/studentSlice";

export const updatePrioridadAlumno = createAsyncThunk("front/updatePrioridadAlumno", async (_, thunkAPI) => {
  try {
    const response = await getPrioridad();
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getPrioridadBuilder = (builder: ActionReducerMapBuilder<StudentState>) => {
  builder.addCase(updatePrioridadAlumno.fulfilled, (state, { payload }) => {
    state.prioridad = payload.tienePrioridad;
    state.mensajePrioridad = payload.mensajePrioridad;
  });
  builder.addCase(updatePrioridadAlumno.rejected, (state, action) => {
  });
};
