import axios from "axios";

export const appState = async () => {
  try {
    const { data, status } = await axios.get(`/state?r=${Math.random().toString()}`);
    return data;
  } catch (error) {
    return { error };
  }
};

export const getModoCarrito = async () => {
  const { data } = await axios.post(`/modoCarrito?r=${Math.random().toString()}`);
  return data;
};
