import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { IInscribedSubjectSection } from "../types";

interface SubjectDetailInterface {
  sections: IInscribedSubjectSection[];
}

interface UpdateSubjectDetailPayload {
  sections: IInscribedSubjectSection[]
}

const initialState: SubjectDetailInterface = {
  sections: [],
};

export const detailSubjectSlice = createSlice({
  name: "detailSubject",
  initialState,
  reducers: {
    updateDetailSubject: (state, action:PayloadAction<UpdateSubjectDetailPayload>) => {
      state.sections = action.payload.sections
    },
    
  },
});

export const selectDetailSubject = (state: RootState) =>
  state.detailSubject;

export const {
  updateDetailSubject,
} = detailSubjectSlice.actions;

export default detailSubjectSlice.reducer;
