import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";

interface EditionModeState {
  on: boolean;
}

interface UpdateEditionModePayload {
  on: boolean;
}

const initialState: EditionModeState = {
  on: false,
};

export const editionModeSlice = createSlice({
  name: "editionMode",
  initialState,
  reducers: {
    updateEditionModeData: (
      state,
      action: PayloadAction<UpdateEditionModePayload>
    ) => {
      state.on = action.payload.on;
    },
  },
});

export const selectEditionMode = (state: RootState) => state.editionMode;

export const { updateEditionModeData } = editionModeSlice.actions;

export default editionModeSlice.reducer;
