import { useState } from "react";
import "./SearchInput.css";
import { IoSearchOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { useAppDispatch } from "../../app/store";
import { selectSearchSubjectsValue, setSearchSubjectsValue } from "../../reduxSlices/subjectsSlice";
import { useSelector } from "react-redux";

const SearchInput = () => {
  const [focus, setFocus] = useState(false);
  const dispatch = useAppDispatch();
  const searchValue = useSelector(selectSearchSubjectsValue);

  const setSearchValue = async (value: string) => {
    await dispatch(setSearchSubjectsValue(value));
  };

  const handleFocus = (bool: boolean) => {
    setFocus(bool);
  };

  const handleInputChange = (event: any) => {
    const value = event.target.value;
    setSearchValue(value);
  };

  const handleClearSearchAndInput = () => {
    setSearchValue("");
  };

  return (
    <form className={`search-container ${focus ? "input-focus-border" : ""}`}>
      <IoSearchOutline className="search-icons" />
      <input
        className="search-input"
        type="text"
        value={searchValue}
        onChange={handleInputChange}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        placeholder={""}
      />
      {searchValue?.length > 0 && (
        <button className="clear-search" onClick={handleClearSearchAndInput}>
          <IoCloseOutline className="search-icons" />
        </button>
      )}
    </form>
  );
};

export default SearchInput;
