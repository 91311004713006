import "./Pages.css";
import { useNavigate } from "react-router-dom";
import BlockedScreen from "../components/BlockedScreen/BlockedScreen";
import { selectStudent, setToken } from "../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { useAppDispatch } from "../app/store";


const BlockedPage = () => {
  const student = useSelector(selectStudent);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const { instance } = useMsal();


  if (!student.bloqueo.activo) {
    navigate("/login");
    return null;
  }  

  const handleGoLogin = () => {
    handleLogout()
  };  


  const handleLogout = async () => {
    localStorage.clear();
    sessionStorage.clear()
    await dispatch(setToken(""));
    await instance.logoutRedirect({
      postLogoutRedirectUri: redirectUri,
    });
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <BlockedScreen msg={student.bloqueo.mensaje} contactMsg={student.bloqueo.subMensaje} />
      <button onClick={handleGoLogin} className="back-to-login-btn">
        Volver al inicio
      </button>
    </div>
  );
};

export default BlockedPage;
