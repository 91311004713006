import "./ModeHeader.css";
import { useMsal } from "@azure/msal-react";
import { useSelector } from "react-redux";
import { selectParameter } from "../../reduxSlices/parametersSlice";
import { selectAdmin } from "../../reduxSlices/adminSlice";
import { conf } from "../../utils/config";
const pjson = require("../../../package.json");

const ModeHeader = () => {
  const { appState } = useSelector(selectParameter);
  const { name } = useSelector(selectAdmin);
  const redirectUri = process.env.REACT_APP_REDIRECT_URI;
  const { instance } = useMsal();

  const entorno = conf("ENV");
  const version = `-v${pjson.version}`;

  const appMode = ["Abierto", "Sistema cerrado", "BypassMode", "Sistema en mantenimiento"];
  const mode = appState === "0" ? "Remote control" : appMode[Number(appState)];

  const handleCloseMode = async () => {
    sessionStorage.clear();
    localStorage.clear();
    await instance.logoutRedirect({
      postLogoutRedirectUri: redirectUri,
    });
  };

  if (appState === "0" && !name) {
    return null;
  }

  return (
    <div className={`mode-header ${appState === "2" || name ? "bypass" : ""} ${appState === "3" ? "maintenance" : ""}`}>
      {name && (
        <>
          <p>{name}</p>
          <p> | </p>
        </>
      )}
      <p>{mode}</p>
      <p>
        {entorno}
        {version}
      </p>
      {appState === "2" || (name && <strong onClick={handleCloseMode}>x</strong>)}
    </div>
  );
};

export default ModeHeader;
