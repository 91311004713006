import "./ErrorScreen.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export default function ErrorScreen() {
  return (
    <div className="error-screen-container">
      <div className="error-alert-box">
      <SlIcon className="error-alert-icon" name="hdd-rack"></SlIcon>
        <div className="">
          <h2>Hubo un error.</h2>
          <p>Alguno de los servicios puede no encontrarse disponible.</p>
        </div>
      </div>
    </div>
  );
}
