import { useAppDispatch } from "../../app/store";
import {
  addCalendarSubject,
  addCalendarSubjectDemoCount,
  removeCalendarSubject,
  selectCalendarSubjectsList,
  selectPinnedSubjectsList,
  selectSubjectsQuotas,
  setDetailSubjectOffered,
  setLoadingRequestInTransit,
  setPinnedSubjectsList,
  subtractCalendarSubjectDemoCount,
} from "../../reduxSlices/subjectsSlice";
import {
  ICalendarSubjects,
  IDesktopCalendarSubjects,
  IOfferedSubject,
  IOfferedSubjectSection,
  ISelectedSection,
} from "../../types";
import SlDivider from "@shoelace-style/shoelace/dist/react/divider";

import "./PanelDrawerInscribed.css";
import { IoMdClose } from "react-icons/io";
import { useSelector } from "react-redux";
import { getQuotaColor, parseHours, transformHorariosData } from "../../utils";
import { getMateriasFijadasAlumno, toggleMateriaFijadaAlumno } from "../../Api/subject";
import { selectStudent } from "../../reduxSlices/studentSlice";
import "animate.css";
import { reserveSection } from "../../Api/reservation";
import { FaCheck } from "react-icons/fa";
import ProfessorAndSubjectHours from "../ProfessorAndSubjectHours/ProfessorAndSubjectHours";

interface IPropsPanelDrawer {
  subject: IOfferedSubjectSection;
}

const findProfeTitular = (subject: IOfferedSubjectSection) => {
  let profe;
  subject.subjectData.subjectsByType.forEach((cursada) => {
    cursada.subjectInfo.forEach((horario) => {
      if (horario.values.rol === "Profesor") {
        profe = horario.values.profesores[0];
      }
    });
  });
  return profe ? profe : "";
};

const PanelDrawerInscribed = ({ subject }: IPropsPanelDrawer) => {
  const dispatch = useAppDispatch();
  const student = useSelector(selectStudent);
  const subjectsQuotas = useSelector(selectSubjectsQuotas);
  const calendarSubsList = useSelector(selectCalendarSubjectsList);
  const pinnedSubjectsList = useSelector(selectPinnedSubjectsList);

  const calendarize = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === subject.id);
  const pinned = pinnedSubjectsList.indexOf(subject.id) > -1;
  const quota = subjectsQuotas[subject.id];
  const quotaQty = subjectsQuotas[subject.id].qty;

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */

  const handleClose = () => {
    dispatch(setDetailSubjectOffered(null));
  };

  const handleToggleCalendarSubject = async (idCurso: number) => {
    const exist = calendarSubsList.some((object: ICalendarSubjects) => object.idCurso === idCurso);
    if (exist) {
      dispatch(removeCalendarSubject(idCurso));
      dispatch(subtractCalendarSubjectDemoCount());

      return;
    }

    let listado: IDesktopCalendarSubjects[] = [];

    dispatch(addCalendarSubjectDemoCount());

    subject.subjectData.subjectsByType.forEach((dia) => {
      dia.subjectInfo.forEach((horario: any) => {
        const newDemo: IDesktopCalendarSubjects = {
          curso: subject.section,
          desde: horario.values.desde,
          hasta: horario.values.hasta,
          dia: horario.values.dia,
          idCurso: idCurso,
          idMateria: Number(subject.idMateria),
          profesor: horario.values.profesores[0],
          warning: false,
          realInscribed: false,
          superpuesta: 0,
        };

        listado.push(newDemo);
      });
    });
    listado.forEach((horario) => dispatch(addCalendarSubject(horario)));
  };

  const handlePinSubject = async (idCurso: number, pinned: boolean) => {
    const result = await toggleMateriaFijadaAlumno(student.id, idCurso);
    const fijadas = await getMateriasFijadasAlumno(student.id);
    if (fijadas.status === 200 && fijadas.data !== null) {
      dispatch(setPinnedSubjectsList(fijadas.data));
    } else {
      alert("Error al fijar materia");
    }
  };

  const handleTryInscribe = async (data: any) => {
    try {
      const results = await reserveSection({
        idC: data.idC,
        idS: data.idS,
        idA: data.idA,
        tim: data.tim,
        tic: data.tic,
        idPersona: student.id.toString(),
      });


      if (results.data.responseCode !== 200) {
        // dispatch(removeSelectedOfferedSubject(selectedSection.idS));
        // setReserveError({
        //   ...reserveError,
        //   [selectedSection.idC]: results.data.responseMessage,
        // });
        // showError(results.data.responseMessage, () =>
        //   setReserveError((prevState) => {
        //     const newState = prevState;
        //     delete newState[selectedSection.idC];
        //     return newState;
        //   })
        // );
      } else {
        await dispatch(setLoadingRequestInTransit(true));
      }
    } catch (error) {
      console.log("error :>> ", error);
    } finally {
    }
  };

  const tagsListingRender = (tags: string) => {
    const colors = ["#C47DDD", "#2277DA", "#209DAE", "#97D86C", "#FBC54F", "#FF7A30", "#FF7A30"];
    const list = tags.split(",").map((item) => item.trim());

    return list.map((tag, i) => <p style={{ background: colors[i < colors.length ? i : colors.length % i] }}>{tag}</p>);
  };

  return (
    <div className="desktop-panel-drawer-inscribed animate__animated animate__slideInRight animate__faster">
      <div className="panel-drawer-inscribed-confirmed">
        <p>Inscripción realizada</p>
        <FaCheck />
      </div>

      <div className="desktop-panel-drawer-inscribed-content">
        <div className="panel-drawer-inscribed-description-title">
          <div className="panel-drawer-inscribed-titles">
            <h2 className="panel-drawer-inscribed-title-name">{subject.section}</h2>
            <p className="panel-drawer-inscribed-title-profesor">{findProfeTitular(subject)}</p>
          </div>
          <IoMdClose style={{ color: "black", height: "20px", cursor: "pointer" }} onClick={handleClose} />
        </div>
        <hr />

        <div className="panel-drawer-inscribed-modalidad">
          <div className="panel-drawer-inscribed-modalidad-inner-col">
            <h4 className="panel-drawer-inscribed-h4-title">Año</h4>
            <p>{subject.year}</p>
          </div>
          <div className="panel-drawer-inscribed-modalidad-inner-col">
            {subject.creditos > 0 && (
              <div className="panel-drawe-creditos">
                <h4 className="panel-drawer-inscribed-h4-title">Créditos</h4>
                <p>{subject.creditos}</p>
              </div>
            )}
          </div>
        </div>
        <hr />

        <div className="panel-drawer-inscribed-description-horarios">
          <ProfessorAndSubjectHours subjectData={subject.subjectData} showRol />
        </div>

        {subject.tag && (
          <>
            <hr />
            <div className="panel-drawer-description-tags">
              <h4 className="panel-drawer-h4-title">Campo menor</h4>
              <div className="panel-tags-list-container">{tagsListingRender(subject.tag)}</div>
            </div>
          </>
        )}

        {subject.obs && (
          <>
            <hr />
            <div className="panel-drawer-inscribed-description-observaciones">
              <h4 className="panel-drawer-inscribed-h4-title">Observaciones</h4>
              <p className="panel-drawer-inscribed-description-text">{subject.obs}</p>
            </div>
          </>
        )}

        {subject.catalogo && (
          <div className="panel-drawer-inscribed-description">
            <h4 className="panel-drawer-inscribed-h4-title">Descripcion del curso</h4>
            <p className="panel-drawer-inscribed-description-text">{subject.catalogo ? subject.catalogo : ""}</p>
          </div>
        )}

        {/* <pre className="" style={{border:"1px solid black"}}>{JSON.stringify(subject,null,2)}</pre> */}
      </div>
    </div>
  );
};

export default PanelDrawerInscribed;
