import { useSelector } from "react-redux";
import "./FlexCalendar.css";
import {
  removeAllCalendarSubject,
  removeCalendarSubject,
  selectCalendarSubjectsList,
  selectInscribedSubjects,
  setDetailSubjectInscribed,
  setDetailSubjectOffered,
} from "../../reduxSlices/subjectsSlice";
import { useEffect, useRef, useState } from "react";
import { ICalendarSubjects, IDesktopCalendarSubjects, IInscribedSubject } from "../../types";
import { assignColorToSubject } from "../../utils/calendarHelpers";
import { createSubjectSlug } from "../../utils";
import EmptyCalendar from "../EmptyCalendar/EmptyCalendar";
import { GiBroom } from "react-icons/gi";
import { FaCircleExclamation } from "react-icons/fa6";
import { useAppDispatch } from "../../app/store";
import { setPanelFilters } from "../../reduxSlices/globalFlagsSlice";

const generateCourseDictionary = (subjects: IDesktopCalendarSubjects[]) => {
  const courseDictionary: Record<number, number> = {};
  let currentNumber = 0;

  subjects.forEach((subject) => {
    if (!(subject.idCurso in courseDictionary)) {
      courseDictionary[subject.idCurso] = currentNumber;
      currentNumber++;
    }
  });

  return courseDictionary;
};
const addSuperpuestaAndColorProperties = (listado: ICalendarSubjects[]) => {
  const colors = [
    "#2277DA",
    "#FF4789",
    "#FF7A30",
    "#FBC54F",
    "#97D86C",
    "#2ED4C6",
    "#C47DDD",
    "#2277DA",
    "#FF4789",
    "#FF7A30",
    "#FBC54F",
    "#97D86C",
    "#2ED4C6",
    "#C47DDD",
  ];

  const diccionariocolors = generateCourseDictionary(listado);

  // Crear un objeto auxiliar para rastrear coincidencias de "dia" y "desde"
  const countMap: { [key: string]: number } = {};

  // Recorrer el listado y actualizar el objeto auxiliar
  listado.forEach((item) => {
    const color = colors[diccionariocolors[item.idCurso]];

    const key = `${item.dia}-${item.desde}`;
    if (countMap[key] !== undefined) {
      countMap[key] += 1;
    } else {
      countMap[key] = 0;
    }

    item.superpuesta = countMap[key];
    item.color = color;
  });

  return listado;
};
function calcularDuracionEnMinutos(horaInicio: number, horaFin: number): number {
  // Obtener las horas y los minutos de la hora de inicio
  const horasInicio = Math.floor(horaInicio / 100);
  const minutosInicio = horaInicio % 100;

  // Obtener las horas y los minutos de la hora de fin
  const horasFin = Math.floor(horaFin / 100);
  const minutosFin = horaFin % 100;

  // Convertir todo a minutos
  const inicioEnMinutos = horasInicio * 60 + minutosInicio;
  const finEnMinutos = horasFin * 60 + minutosFin;

  // Calcular la diferencia
  const duracionEnMinutos = finEnMinutos - inicioEnMinutos;
  return duracionEnMinutos;
}
const getWidthPercentage = (value: number): string => {
  if (value === 0) {
    return "100%";
  } else if (value === 1) {
    return "75%";
  } else if (value === 2) {
    return "50%";
  } else {
    return "25%";
  }
};
function calcularAlturaEnPixeles(inicio: number, horaFormato: number): number {
  const horaInicio = inicio; // Hora de referencia que equivale a 0 píxeles (8:00 AM)
  const alturaPorHora = 60; // Altura en píxeles por cada hora

  // Extraer horas y minutos del formato numérico
  const hora = Math.floor(horaFormato / 100);
  const minutos = horaFormato % 100;

  // Calcular la altura en píxeles
  const altura = (hora - horaInicio) * alturaPorHora + (minutos / 60) * alturaPorHora;

  return altura;
}
const calcularHoraMasTemprana = (horarios: ICalendarSubjects[]) => {
  let min = 22;
  horarios.forEach((horario) => {
    const num = horario.desde / 100;
    if (num < min) {
      min = Math.floor(num);
    }
  });

  return min;
};
const calcularHoraMasTarde = (horarios: ICalendarSubjects[]) => {
  let max = 0;
  horarios.forEach((horario) => {
    const num = horario.hasta / 100;
    if (num > max) {
      max = Math.ceil(num);
    }
  });

  return max;
};

export default function FlexCalendar() {
  const calendarSubjectsList = useSelector(selectCalendarSubjectsList);
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const dispatch = useAppDispatch();

  const [calendarSubjects, setCalendarSubjects] = useState<ICalendarSubjects[]>([]);
  const [minHour, setMinHour] = useState(0);
  const [maxHour, setMaxHour] = useState(0);
  const [saturday, setSaturday] = useState(false);
  const divRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [eventAdedd, setEvenAdedd] = useState(false)

  const franjasHorarias = [
    {
      hora: 8,
      modulo: "8:00",
      minutos: 800,
    },
    {
      hora: 9,
      modulo: "9:45",
      minutos: 945,
    },
    {
      hora: 11,
      modulo: "11:30",
      minutos: 1130,
    },
    {
      hora: 13,
      modulo: "13:45",
      minutos: 1345,
    },
    {
      hora: 15,
      modulo: "15:30",
      minutos: 1530,
    },
    {
      hora: 17,
      modulo: "17:15",
      minutos: 1715,
    },
    {
      hora: 19,
      modulo: "19:00",
      minutos: 1900,
    },
    {
      hora: 20,
      modulo: "20:45",
      minutos: 2045,
    },
    {
      hora: 23,
      modulo: "23:00",
      minutos: 2300,
    },
  ];

  const filterRepeatCalendarSubs = (calendarSubs: ICalendarSubjects[]) => {
    return calendarSubs.filter(
      (cs: any, index: any, self: any[]) =>
        index ===
        self.findIndex(
          (c) => c.idMateria === cs.idMateria && c.dia === cs.dia && c.desde === cs.desde && c.hasta === cs.hasta
        )
    );
  };

  /* -------------------------------------------------------------------------- */
  /*                                   EFFECTS                                  */
  /* -------------------------------------------------------------------------- */

  const updateDimensions = () => {
    if (divRef.current) {
      const { clientWidth, clientHeight } = divRef.current;
      setDimensions({
        width: clientWidth,
        height: clientHeight,
      });
    }
  };

  useEffect(() => {
    // Dimensiones para posicionar el btn Clean
    updateDimensions();
    
    const divElement = divRef.current;
    if(!eventAdedd){        
      window.addEventListener("resize", updateDimensions);
      if (divElement) {
        divElement.addEventListener("scroll", updateDimensions);
      }
      setEvenAdedd(true)
    }
    return () => {
      window.removeEventListener("resize", updateDimensions);
      if (divElement) {
        divElement.removeEventListener("scroll", updateDimensions);
      }
    };
  }, [calendarSubjects]);

  useEffect(() => {
    if (inscribedSubjects && inscribedSubjects.length) {
      const toCalendarSubjects = () => {
        return inscribedSubjects.reduce((acc, i) => {
          const newSections: any = i.sections.map((s: any) => {
            const subject = {
              idMateria: i.id,
              idCurso: s.idCurso,
              dia: s.subjectData.dia,
              desde: Number(s.subjectData.desde),
              hasta: Number(s.subjectData.hasta),
              profesor: s.subjectData.profesor,
              curso: s.title,
              colors: assignColorToSubject(Number(i.id)),
              warning: false,
              realInscribed: true,
              superpuesta: 0,
            };

            return subject;
          });

          return acc.concat(newSections);
        }, []);
      };
      const calendarSubs = toCalendarSubjects();
      
      const calendarSubsWithoutRep = filterRepeatCalendarSubs(calendarSubs);
      const calendarSubjectListWithoutRep = filterRepeatCalendarSubs(calendarSubjectsList);
      const calendarSubsInscribedAndStore = [...calendarSubsWithoutRep, ...calendarSubjectListWithoutRep];

      /* --------------------- Setting materias calendar --------------------- */
      const copyList = JSON.stringify(calendarSubsInscribedAndStore);
      const parsedSubjects = addSuperpuestaAndColorProperties(JSON.parse(copyList));
      setCalendarSubjects(parsedSubjects);
    }
    if (inscribedSubjects === null || inscribedSubjects?.length === 0) {
      /* --------------------- Setting materias calendar --------------------- */
      const copyList = JSON.stringify(calendarSubjectsList);
      const parsedSubjects = addSuperpuestaAndColorProperties(JSON.parse(copyList));
      setCalendarSubjects(parsedSubjects);
    }
  }, [inscribedSubjects, calendarSubjectsList]);

  useEffect(() => {
    // setMinHour(calcularHoraMasTemprana(calendarSubjects));
    // setMaxHour(calcularHoraMasTarde(calendarSubjects));
    setMinHour(8);
    setMaxHour(22);

    const earlySubjects = calendarSubjects.filter((sub) => sub.desde < 1700);
    if (divRef.current && calendarSubjects.length && earlySubjects.length === 0) {
      divRef.current.scrollTop = divRef.current.scrollHeight;
    }

    const saturdayFilter = calendarSubjects.filter((curso) => curso.dia === "Sábado");
    if (saturdayFilter.length > 0) {
      setSaturday(true);
    } else {
      setSaturday(false);
    }
  }, [calendarSubjects]);

  useEffect(() => {
    const removeFromCalendarizeIfInscribed = (inscriptas: IInscribedSubject[], calendarizadas: ICalendarSubjects[]) => {
      const idsInscriptas = inscriptas.map((s) => s.idCurso);
      idsInscriptas.forEach((id) => {
        const existe = calendarizadas.some((item) => item.idCurso === id);
        if (existe) {
          dispatch(removeCalendarSubject(id));
        }
      });
    };
    if (inscribedSubjects && inscribedSubjects?.length > 0) {
      removeFromCalendarizeIfInscribed(inscribedSubjects, calendarSubjects);
    }
  }, [inscribedSubjects]);

  /* -------------------------------------------------------------------------- */
  /*                              CALENDARIO VACIO                              */
  /* -------------------------------------------------------------------------- */
  if (calendarSubjectsList.length === 0 && (inscribedSubjects === null || inscribedSubjects.length === 0)) {
    return <EmptyCalendar />;
  }

  /* -------------------------------------------------------------------------- */
  /*                                  HANDLERS                                  */
  /* -------------------------------------------------------------------------- */
  const handleClearCalendarize = () => {
    dispatch(removeAllCalendarSubject());
  };

  const handleShowSubjectoInfo = (materiaId: number, id: number) => {
    // const detail = inscribedSubjects?.filter(sub=> sub.idCurso === id)
    dispatch(setDetailSubjectInscribed({ subjectId: materiaId, cursoId: id }));
    dispatch(setPanelFilters(false));
  };

  /* -------------------------------------------------------------------------- */
  /*                                SUBCOMPONENTS                               */
  /* -------------------------------------------------------------------------- */
  const renderCalendarSubjects = (subjects: ICalendarSubjects[]) => {
    return subjects.map((m) => {
      return (
        <div
          onClick={() => handleShowSubjectoInfo(m.idMateria, m.idCurso)}
          key={"weekly" + m.idMateria + m.dia + m.desde + m.profesor + m.idCurso}
          className="flexcalendar-subject-card"
          style={{
            height: `${calcularDuracionEnMinutos(m.desde, m.hasta)}px`,
            top: `${calcularAlturaEnPixeles(minHour, m.desde)}px`,
            width: getWidthPercentage(m.superpuesta),
            borderColor: m.color,
            backgroundColor: m.realInscribed ? m.color : "white",
            cursor: m.realInscribed ? "pointer" : "default",
          }}
        >
          <h5 style={{ color: `${m.realInscribed ? "white" : m.color}` }}>
            {/* {createSubjectSlug(m.curso)} */}
            {m.curso}
          </h5>
          {m.superpuesta > 0 && (
            <FaCircleExclamation title="Superposicion horaria" className="flexcalendar-warn-icon" />
          )}
        </div>
      );
    });
  };

  return (
    <div className="flexcalendar-container" id="flexcalendar" ref={divRef}>
      {/* <div className="">{JSON.stringify(calendarSubjects)}</div> */}

      <div className="flexcalendar-dias">
        <h4>Lunes</h4>
        <h4>Martes</h4>
        <h4>Miércoles</h4>
        <h4>Jueves</h4>
        <h4>Viernes</h4>
        {saturday && <h4>Sábado</h4>}
      </div>

      <div className="flexcalendar-horarios">
        {franjasHorarias.map((item) => {
          return (
            <div
              key={item.modulo}
              className="flexcalendar-horarios-row"
              style={{
                width: "100%",
                height: "60px",
                position: "absolute",
                top: `${calcularDuracionEnMinutos(minHour * 100, item.minutos)}px`,
              }}
            >
              <p className="flexcalendar-horarios-row-title">{item.modulo}</p>
            </div>
          );
        })}

        {/* {Array.from({ length: maxHour - minHour }, (_, i) => i + minHour).map((hora) => {
          return (
            <div key={hora}
            className="flexcalendar-horarios-row"
            style={{ width: "100%", height: "60px", position: "absolute", top: "250px" }}
          >
            <p className="flexcalendar-horarios-row-title">17:30</p>
          </div>
            
          );
        })} */}
        {/* {Array.from({ length: maxHour - minHour }, (_, i) => i + minHour).map((hora) => {
          return (
            <div key={hora} className="flexcalendar-horarios-row" style={{ height: "60px" }}>
              <p className="flexcalendar-horarios-row-title">{hora}:00</p>
            </div>
          );
        })} */}
      </div>

      <div className="flexcalendar-cursadas">
        <div className="flexcalendar-cursadas-col">
          {/* Lunes */}
          {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Lunes"))}
        </div>
        <div className="flexcalendar-col-divider"></div>
        <div className="flexcalendar-cursadas-col">
          {/* Martes */}
          {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Martes"))}
        </div>
        <div className="flexcalendar-col-divider"></div>
        <div className="flexcalendar-cursadas-col">
          {/* Miercoles */}
          {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Miércoles"))}
        </div>
        <div className="flexcalendar-col-divider"></div>
        <div className="flexcalendar-cursadas-col">
          {/* Jueves */}
          {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Jueves"))}
        </div>
        <div className="flexcalendar-col-divider"></div>
        <div className="flexcalendar-cursadas-col">
          {/* Viernes */}
          {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Viernes"))}
        </div>
        {saturday && (
          <>
            <div className="flexcalendar-col-divider"></div>
            <div className="flexcalendar-cursadas-col">
              {/* Sábado */}
              {renderCalendarSubjects(calendarSubjects.filter((item) => item.dia === "Sábado"))}
            </div>
          </>
        )}
      </div>

      {calendarSubjectsList.length > 0 && (
        <button
          className="flexcalendar-btn-clear"
          onClick={handleClearCalendarize}
          style={{ top: dimensions.height - 35, left: dimensions.width - 3 }}
        >
          <GiBroom />
        </button>
      )}
    </div>
  );
}
