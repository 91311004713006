import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { ParametersState } from "../reduxSlices/parametersSlice";
import { getModoCarrito } from "../Api/parameters";

export const getModoCarritoParam = createAsyncThunk("front/getModoCarritoParam", async (_, thunkAPI) => {
  try {
    const response = await getModoCarrito();
    return response;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const getModoCarritoBuilder = (builder: ActionReducerMapBuilder<ParametersState>) => {
  builder.addCase(getModoCarritoParam.fulfilled, (state, action) => {
    state.cartMode = action.payload;
  });
  builder.addCase(getModoCarritoParam.rejected, (state, action) => {
  });
};
