import React, { useEffect } from "react";
import { parseHours, transformHorariosData } from "../../utils";
import { IOfferedSubjectSection } from "../../types";
import { selectStudent } from "../../reduxSlices/studentSlice";
import { useSelector } from "react-redux";
import "./ProfessorAndSubjectHours.css";
interface Props {
  subjectData: IOfferedSubjectSection["subjectData"];
  itemClass?: string;
  titleClass?: string;
  showBorderTop?: boolean;
  showRol?: boolean;
  allProfesVisible?: boolean;
}

function ProfessorAndSubjectHours({
  subjectData,
  showBorderTop = false,
  showRol = false,
  itemClass,
  titleClass,
  allProfesVisible = true
}: Props) {
  const student = useSelector(selectStudent);

  return (
    <div className={`content-rows-ph row-ph ${showBorderTop ? "border-top-ph" : ""}`}>
      {subjectData.subjectsByType &&
        transformHorariosData(subjectData.subjectsByType).map((tipo: any, index: number) => {
          const renderProfesor = tipo.titulo === "Profesor";
          const renderHorarios = !renderProfesor;
          const mostrarHorarios = student.mostrarHorarios;
          if (renderProfesor) {
            return (
              <div className={`${itemClass ? itemClass : "ph-horarios-inner-col"}`} key={Math.random() + index}>
                <h4 className={`ph-h4-title ${titleClass}`}>{tipo.titulo}</h4>
                {allProfesVisible ? tipo.detalles.map((persona: any, i: number) => (
                  <p key={Math.random() + i}>
                    {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                    {persona.rol && !persona.rol.includes("null") && showRol && (
                      <span className="ph-professor-rol-pill">{persona.rol}</span>
                    )}
                  </p>
                )) :
                tipo.detalles.slice(0,1).map((persona: any, i: number) => (
                  <p key={Math.random() + i}>
                    {persona.nombre.includes("null") ? "A designar" : persona.nombre}
                    {persona.rol && !persona.rol.includes("null") && showRol && (
                      <span className="ph-professor-rol-pill">{persona.rol}</span>
                    )}
                  </p>
                ))
              }
              </div>
            );
          } else if (renderHorarios && mostrarHorarios) {
            return (
              <div className={`${itemClass ? itemClass : "ph-horarios-inner-col"}`} key={Math.random() + index}>
                <h4 className={`ph-h4-title ${titleClass}`}>{tipo.titulo}</h4>
                {tipo.titulo !== null &&
                  tipo.detalles.map((horario: any, ind: number) => (
                    <p key={Math.random() + ind}>
                      {horario?.dia.slice(0, 2)} {parseHours(horario.desde)} a {parseHours(horario.hasta)} h
                    </p>
                  ))}
              </div>
            );
          }
        })}
    </div>
  );
}

export default ProfessorAndSubjectHours;
