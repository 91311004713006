import axios from "axios";
import { IOfferedReserveData } from "../types";

export const getMateriasAlumno = async (idPersona: string) => {
  const { data, status } = await axios.post(`/api/materias?r=${Math.random().toString()}`, { idPersona });
  return { data, status };
};

export const getMateriasInscriptasAlumno = async (idPersona: number) => {
  const { data, status } = await axios.post(`/api/materiasInscriptas?r=${Math.random().toString()}`, {
    id: idPersona,
  });
  return { data, status };
};

export const getMateriasFijadasAlumno = async (idPersona: number) => {
  const { data, status } = await axios.post(`/api/materiasFijadas?r=${Math.random().toString()}`, {
    id: idPersona,
  });
  return { data, status };
};

export const toggleMateriaFijadaAlumno = async (idPersona: number, cursoId:number) => {
  const { data, status } = await axios.post(`/api/toggleMateriasFijadas?r=${Math.random().toString()}`, {
    id: idPersona,
    idCurso: cursoId
  });
  return { data, status };
};

export const checkCalendarizeSubject = async (reserveData:IOfferedReserveData) => {
  const { data, status } = await axios.post(`/api/validarInscripcion`, {
      idA: reserveData.idA,
      idC: reserveData.idC,
      idS: reserveData.idS,
      tim: reserveData.tim,
      tic: reserveData.tic,
  });
  return { data, status };
};
