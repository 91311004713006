import { useSelector } from "react-redux";
import DesktopHeader from "../DesktopHeader/DesktopHeader";
import "./QuitViewScreen.css";
import { MdOutlineScreenshotMonitor } from "react-icons/md";
import { selectStudent } from "../../reduxSlices/studentSlice";
import logo from "../../assets/ditella-logo.png";

export default function QuitViewScreen() {
  const student = useSelector(selectStudent);
  const { carreraActual } = useSelector(selectStudent);

  return (
    <div className="size-screen-container">

      {student.email && student.id && carreraActual ? (
        <DesktopHeader />
      ) : (
        <img className="size-screen-logo" src={logo} alt="logo Ditella" width={150} />
      )}

      <div className="size-alert-box">
        <MdOutlineScreenshotMonitor className="size-alert-icon" />
        <div>
          <h2>Tamano de pantalla insuficiente</h2>
          <p>Para ingresar al sistema debes hacerlo desde una laptop o pc de escritorio.</p>
        </div>
      </div>

    </div>
  );
}
