import "./BlockedScreen.css";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

interface Props {
  msg: string;
  contactMsg: string;
}
export default function BlockedScreen({ msg, contactMsg }: Props) {
  return (
    <div className="blocked-screen-container">
      <div className="blocked-alert-box">
        <SlIcon className="blocked-alert-icon" name="exclamation-octagon"></SlIcon>
        <div className="">
          <h2>El acceso a la plataforma se encuentra bloqueado.</h2>
          <p>{msg ? msg : "Consulte con el departamente de Alumnos su situación."}</p>
          {contactMsg && (
            <div className="blocked-contactos">
              <p>{contactMsg}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
