import "./DesktopTab.css";

interface IPropsTabs {
  handleSelectTab: (tipo: string) => void;
  filtro: string;
}

export default function DesktopTab({ handleSelectTab, filtro }: IPropsTabs) {
  return (
    <div className="desktop-subjects-tab">
      <p
        className={`desktop-subjects-tab-button ${
          filtro === "OBLIGATORIO" ? "desktop-tab-active" : ""
        }`}
        onClick={() => {
          handleSelectTab("OBLIGATORIO");
        }}
      >
        Obligatorias
      </p>
      <p
        className={`desktop-subjects-tab-button ${
          filtro === "OPTATIVO" ? "desktop-tab-active" : ""
        }`}
        onClick={() => {
          handleSelectTab("OPTATIVO");
        }}
      >
        Optativas
      </p>
    </div>
  );
}
