import "./Pages.css";
import { useNavigate } from "react-router-dom";
import { selectParameter } from "../reduxSlices/parametersSlice";
import { useSelector } from "react-redux";
import UnknownUserScreen from "../components/UnknownUserScreen/UnknownUserScreen";

const UnknownUserPage = () => {
  const { unknownUser } = useSelector(selectParameter);
  const navigate = useNavigate();

  if (!unknownUser) {
    navigate("/login");
    return null;
  }

  const handleGoBack = () => {
    navigate("/login");
  };

  return (
    <div style={{ textAlign: "center", marginTop: "50px" }}>
      <UnknownUserScreen />
      <button onClick={handleGoBack} className="back-to-login-btn">Volver al inicio</button>
    </div>
  );
};

export default UnknownUserPage;
