import { useEffect, useState } from "react";
import DesktopOfferList from "../DesktopOfferList/DesktopOfferList";
import DesktopSelectedSubjects from "../DesktopSelectedSubjects/DesktopSelectedSubjects";
import "./DesktopMain.css";
import { useSelector } from "react-redux";
import { selectInscribedSubjects } from "../../reduxSlices/subjectsSlice";
import { selectStudent } from "../../reduxSlices/studentSlice";
import FlexCalendar from "../FlexCalendar/FlexCalendar";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

function DesktopMain() {
  const inscribedSubjects = useSelector(selectInscribedSubjects);
  const { prioridad, mensajePrioridad } = useSelector(selectStudent);
  const [bannerPrioridad, setBannerPrioridad] = useState(true);
  const student = useSelector(selectStudent);
  const [tab, setTab] = useState<"calendario" | "materias">(student.mostrarHorarios ? "calendario" : "materias");

  useEffect(() => {   
    if(!student.mostrarHorarios){
      setTab("materias")
    } 
  }, [student.mostrarHorarios])
  

  const handleDesktopTab = (tab: "calendario" | "materias") => {
    setTab(tab);
  };

  const handleBannerPrioridad = (bool: boolean) => {
    setBannerPrioridad(bool);
  };
  return (
    <div className="desktop-main">
      <div className="desktop-left">
        <h2 className="inscripciones-title">Inscripciones</h2>
        <div className="inner-left-box">
        <div className="desktop-inscribed-calendar-tab">
          <button
            className={`desktop-left-tab ${tab === "materias" ? "desktop-left-tab-active" : false}`}
            onClick={() => handleDesktopTab("materias")}
          >
            Mis cursos
            <p className="desktop-inscribed-quantity">{!inscribedSubjects ? 0 : inscribedSubjects.length}</p>
          </button>
          {student.mostrarHorarios && (
            <button
              className={`desktop-left-tab ${tab === "calendario" ? "desktop-left-tab-active" : false}`}
              onClick={() => handleDesktopTab("calendario")}
            >
              Calendario
            </button>
          )}
        </div>
        {tab === "materias" && <DesktopSelectedSubjects />}
        {/* {tab === "calendario" && <DesktopCalendar />} */}
        {tab === "calendario" && student.mostrarHorarios && <FlexCalendar />}
      </div>
      </div>
      <div className="desktop-right">
        {bannerPrioridad && mensajePrioridad && (
          <div className={`desktop-mensaje-prioridad ${prioridad ? "prioridad-activa" : ""}`}>
            <div className="desktop-mensaje-prioridad-first-col">
              <p>{prioridad ? "✔" : "⚠"}</p>
              <p>{mensajePrioridad}</p>
            </div>
            <SlIcon name="x-circle" onClick={() => handleBannerPrioridad(false)} style={{ cursor: "pointer" }} ></SlIcon>
          </div>
        )}
        <DesktopOfferList />
      </div>
    </div>
  );
}

export default DesktopMain;
