import axios from "axios";

export const login = async (email: string) => {
  try {
    const { data, status } = await axios.post("/doLogin", { email: email });
    return data;
  } catch (error) {
    return { error };
  }
};

export const getEstadoAlumno = async () => {
  try {
    const { data } = await axios.post(`/api/estadoAlumno?r=${Math.random().toString()}`, {});
    return data;
  } catch (error) {
    return { error };
  }
};

export const postSeleccionaCarrera = async (idAlumnoPrograma:number) => {
  try {    
    const { data } = await axios.post(`/api/elegirCarrera`, {idAlumnoPrograma});
    return data;
  } catch (error) {
    return { error };
  }
};

export const auth = async () => {
  try {
    const { data, status } = await axios.get("/auth");
    return data;
  } catch (error) {
    return { error };
  }
};
