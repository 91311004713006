import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";
import './Loader.css'

interface IPropsLoader {
  text: string;
}

const Loader = ({ text }: IPropsLoader) => {
  return (
    <div className="custom-loader">
      <SlSpinner
        style={{
          fontSize: "3rem",
          //@ts-ignore
          "--indicator-color": "#00C4B3",
          "--track-color": "#ccebe8",
        }}
      />
      <p>{text}</p>
    </div>
  );
};

export default Loader;
