import "./Pages.css";
import { useNavigate } from 'react-router-dom';
import ErrorScreen from '../components/ErrorScreen/ErrorScreen';
import { selectParameter } from '../reduxSlices/parametersSlice';
import { useSelector } from 'react-redux';

const ErrorPage = () => {
  const { serverError } = useSelector(selectParameter);
  const navigate = useNavigate();

  if (!serverError) {
    navigate("/login");
    return null;
  }

  const handleGoBack = () => {
    navigate("/login");
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }}>
     <ErrorScreen />
      <button onClick={handleGoBack} className="back-to-login-btn">Volver al inicio</button>
    </div>
  );
};

export default ErrorPage;