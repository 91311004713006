import { configureStore } from '@reduxjs/toolkit'
import studentSlice from '../reduxSlices/studentSlice'
import subjectsSlice from '../reduxSlices/subjectsSlice'
import parametersSlice from '../reduxSlices/parametersSlice'
import adminSlice from '../reduxSlices/adminSlice'
import editorModeSlice from '../reduxSlices/editorModeSlice'
import detailSubjectSlice from '../reduxSlices/detailSubjectSlice'
import { useDispatch } from 'react-redux'
import globalFlagsSlice from '../reduxSlices/globalFlagsSlice'

const store = configureStore({
  reducer: {
    student: studentSlice,
    subjects: subjectsSlice,
    parameters: parametersSlice,
    admin: adminSlice,
    editionMode: editorModeSlice,
    detailSubject: detailSubjectSlice,
    globalFlags: globalFlagsSlice
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store