import "./EmptyCourses.css";
import UndrawCourses from "../../assets/empty-cursos.svg";

function EmptyCourses() {
  return (
    <div className="calendar-image-container">
      <img className="cursos-image" src={UndrawCourses} alt="Sin cursos inscriptos" />
      <p className="empty-calendar-msg">
        No estás inscripto en ninguna materia, selecciona tus materias para comenzar tu inscripción
      </p>
    </div>
  );
}

export default EmptyCourses;
